.team_section {
    background-color: #eeeeee;
    padding: 40px 0;
  }
  
  .team_container {
    margin-top: 30px;
  }
  
  .box {
    background-color: #122574;
    border: 1px solid #122574;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
  }
  
  .img-box img {
    width: 100%;
    height: auto;
  }
  
  .detail-box {
    padding: 20px;
    text-align: center;
  }
  
  .detail-box h5 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .detail-box p {
    color: #ffffff;
    font-size: 14px;
  }
  
  .social_box {
    background-color:#ffffff;
    ;
    padding: 10px;
    display: flex;
    justify-content: center;
  }
  
  .social_box a {
    color: #333;
    margin: 0 10px;
    font-size: 18px;
  }
  
  .social_box a:hover {
    color: #007bff;
  }
  
  .heading_center {
    text-align: center;
  }
  
  .heading_center h2 {
    margin-bottom: 30px;
  }
  
  @media (max-width: 767px) {
    .team_section {
      padding: 20px 0;
    }
  
    .box {
      margin-bottom: 20px;
    }
  }
  